import axios from 'axios';
import http from '../http';

const leadsApi = {
	saveLead(data, v = 0) {
		var url = '/leads';
		if(v) {
			url = url + '?v=' + v;
		}
		return http.post(url, data);
	}
}

export {
	leadsApi
}