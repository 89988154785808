<template>
	<div id="quiz" class="quiz-two screen" :class="{'grey': completed_quiz}">
		<a :href="$app.site_url" class="logo respond">
			<img :src="$app.logo.white" alt="logo" v-show="!completed_quiz">
			<img :src="$app.logo.grey" alt="logo" v-show="completed_quiz">
		</a>
		<div id="quiz-questions" v-show="!completed_quiz">
			<div class="progress clearfix">
				<div class="bar-completion">
					<span class="percentage">0%</span>
				</div>
			</div>
			<div class="step">
				<div class="form-block">
					<label class="form-label" data-field="fullname">
						<span class="label">Full name</span>
						<div class="form-field input">
							<input type="text" placeholder="Full name" @change="setFullname()">
						</div>
					</label>
				</div>
				<button type="button" class="ui-btn ui-btn-rounded ui-btn-block btn-next" @click="changeStep('next')">Next</button>
			</div>
			<div class="step">
				<div class="form-block">
					<label class="form-label" data-field="email">
						<span class="label">Email address</span>
						<div class="form-field input">
							<input v-model="model.email" type="email" name="email" placeholder="Email address">
						</div>
					</label>
				</div>
				<div class="form-block">
					<div class="form-label required" data-field="consent_given">
						<div class="ui-checkbox">
							<label>
								<input type="checkbox" v-model="model.consent_given" name="consent_given" required>
								<span class="checkbox"></span>
								<span>I agree to The MiSmile Network using my personal data to provide me with dental treatment and to share it with local providers within the group.</span>
							</label>
						</div>
					</div>
				</div>
				<div class="form-block">
					<div class="form-label required">
						<div class="ui-checkbox">
							<label>
								<input type="checkbox" v-model="model.marketing_consent_given" name="marketing_consent_given">
								<span class="checkbox"></span>
				 				<span>I agree to The MiSmile Network using my personal data to keep me informed about marketing offers and initiatives that may be of interest and to share it with local providers within the group.</span>
							</label>
						</div>
					</div>
				</div>
				<button type="button" class="ui-btn ui-btn-rounded ui-btn-block btn-next" @click="changeStep('next')" :disabled="!model.consent_given">Next</button>
			</div>
			<div class="step">
				<p class="q-label">My smile has...</p>
				<div class="col-row options column-style">
					<div class="col l6 m6 s6" v-for="option, index in list.smile_options">
						<div class="radio">
							<label class="ui-btn ui-btn-rounded" @click="setValueAndProceed('my_smile_has', option.label, option.info)">
								<img :src="$app.assets_url + '/images/icons/' + option.icon + '.png'" :alt="option.label">
								<input :value="option.label" type="radio" required>
								<span v-text="option.label"></span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="step">
				<p class="q-label">How would you rate your current smile?</p>
				<div id="slider-rate">
					<div class="slider-labels"></div>
					<div id="slider"></div>
					<div class="slider-labels-btm">
						<span>Almost <br> perfect!</span>
						<span>Very <br> misaligned</span>
					</div>
				</div>
				<button type="button" class="ui-btn ui-btn-rounded ui-btn-block btn-next" @click="changeStep('next')">Next</button>
			</div>
			<div class="step">
				<p class="q-label">Do you have any...</p>
				<div class="options">
					<div class="radio" v-for="option, index in list.do_you_have_any">
						<label class="ui-btn ui-btn-rounded" @click="setValueAndProceed('do_you_have_any', option.label, option.info)">
							<input :value="option.label" type="radio" required>
							<span v-text="option.label"></span>
						</label>
					</div>
				</div>
			</div>
			<div class="step">
				<div class="form-block">
					<label class="form-label" data-field="phone_number">
						<span class="label">Phone number</span>
						<div class="form-field input">
							<input v-model="model.phone_number" @keypress="onPhonePress($event)" type="tel" name="mobile" maxlength="11" placeholder="Phone number">
						</div>
					</label>
				</div>
				<button type="button" class="ui-btn ui-btn-rounded ui-btn-block btn-next" @click="changeStep('next')">Next</button>
			</div>
			<div class="step">
				<p class="q-label">Just one more question!</p>
				<div class="form-block">
					<label class="form-label" data-field="postcode">
						<span class="label">Postcode</span>
						<div class="form-field input">
							<input v-model="model.postcode" type="text" name="postcode" maxlength="8" placeholder="Postcode">
						</div>
					</label>
				</div>
				<button type="button" class="ui-btn ui-btn-rounded ui-btn-block btn-next" @click="saveLead()" :disabled="isSavingLead || !model.consent_given" v-text="isSavingLead ? 'Processing...' : 'Submit'">Submit</button>
			</div>
			<div class="invisalign-logo respondmaxi">
				<img :src="$app.assets_url + '/images/logo/logo-invisalign.png'" alt="logo-invisalign">
			</div>
		</div>
		<form id="quiz-form" v-show="completed_quiz" @submit.prevent="saveLead" method="post" action="https://app.dengro.com/register-interest" enctype="multipart/form-data">
			<div class="copy" v-if="!more_info_needed">
				<p class="heading">Congratulations!</p>
				<p><strong>It looks like you’re a candidate for Invisalign treatment!</strong></p>
				<p>Your nearest MiSmile clinic will be in touch soon to book your FREE, no-obligation Smile Evaluation, and show you Invisalign can give you a smile you love.</p>
			</div>
			<div class="copy" v-if="more_info_needed">
				<p class="heading">We'd like to assess your smile.</p>
				<p>We want to be sure you’ll get a result you love with Invisalign! Your nearest MiSmile clinic will be in touch soon to book your FREE, no-obligation Smile Evaluation so one of our experienced providers can fully assess your smile.</p>
			</div>
			<a href="https://www.instagram.com/mismileuk/" class="ui-btn ui-btn-rounded">Follow us on Instagram</a>
			<input type="hidden" name="token" value="b9579122-067b-37c5-8778-41562cddab4b">
			<input type="hidden" name="treatment" value="invisalign">
			<input v-model="model.first_name" type="hidden" name="first_name">
			<input v-model="model.last_name" type="hidden" name="last_name">
			<input v-model="model.email" type="hidden" name="email">
			<input v-model="model.phone_number" type="hidden" name="mobile">
			<input v-model="model.postcode" type="hidden" name="postcode">
			<input v-model="model.my_smile_has" type="hidden" name="meta_data[my_smile_has]" required>
			<input v-model="model.smile_rate" type="hidden" name="meta_data[smile_rate]" required>
			<input v-model="model.do_you_have_any" type="hidden" name="meta_data[do_you_have_any]" required>
			<input v-for="param, i in getUtmParams" type="hidden" :name="param.key" :value="param.value">
		</form>
	</div>
</template>
<script>
	import {slider} from 'jquery-ui';
	import helper from '../../helpers';
	import {leadsApi} from '../../api';
	import {includes} from 'lodash';
	export default {
		data() {
			return {
				more_info_needed: false,
				isSavingLead: false,
				completed_quiz: false,
				params: {},
				model: {},
				step: 1,
				total_steps: 0,
				smile_rating_options: [
					"congrats",
					"congrats",
					"congrats",
					"more-info",
					"more-info",
				],
				list: {
					smile_options: [
						{label: "Crowded Teeth", info: "congrats", icon: "Wireframe-_Overcrowded"},
						{label: "Gapped Teeth", info: "congrats", icon: "Wireframe-_Gapped teeth"},
						{label: "Underbite", info: "more-info", icon: "Wireframe-_Underbite"},
						{label: "Open Bite", info: "more-info", icon: "Wireframe-_Open Bite"},
						{label: "Crossbite", info: "more-info", icon: "Wireframe-_Cross bite"},
						{label: "Deep Bite", info: "more-info", icon: "Wireframe-_Deepbite"},
					],
					do_you_have_any: [
						{label: "Implants", info: "more-info"},
						{label: "Bridges", info: "more-info"},
						{label: "Missing teeth", info: "more-info"},
						{label: "None of these", info: "congrats"},
					],
				}
			}
		},
		methods: {
			setFullname() {
				let self = this, fullname = $('[data-field="fullname"] input').val(), first_name = "", last_name = "";
				fullname = fullname.split(' ');
				first_name = fullname[0];
				last_name = fullname.length === 1 ? '' : fullname.slice(-1).pop();
				self.model.first_name = first_name;
				self.model.last_name = last_name;
				self.$forceUpdate();
			},
			onPhonePress(evt) {
				evt = (evt) ? evt : window.event;
				var charCode = (evt.which) ? evt.which : evt.keyCode;
				if((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			saveLead() {
				let self = this;
				self.isSavingLead = true;
				$('.error').remove();
				leadsApi.saveLead(self.model, 2).then(res => {
					// submit form
					var form = $('#quiz-form');
					$.ajax({
						type: "POST",
						url: form.attr("action"),
						data: form.serialize(),
						success: function(result) {
							self.completed_quiz = true;
						},
						error: function(e) {
							console.log(e);
						}
					});
				}, error => {
					self.isSavingLead = false;
					let errors = error.response.data;
					console.log(errors);
					if(errors.errors) {
						Object.entries(errors.errors).forEach((value, index) => {
							$('[data-field="' + value[0] + '"]').append('<span class="error">' + value[1] + '</span>');
						});
						var idx = $('[data-field="' + Object.keys(errors.errors)[0] + '"]').parents('.step').index();
						self.step = idx - 1;
						self.goToStep(idx - 1);
					}
				});
			},
			setValue(key, value, info) {
				let self = this;
				self.model[key] = value;
				if(info === "more-info") {
					self.more_info_needed = true;
				}
			},
			setValueAndProceed(key, value, info) {
				let self = this;
				self.setValue(key, value, info);
				self.changeStep('next');
			},
			validateForm() {
				let self = this, model = self.model;
				var isValid = false, error = {};
				switch(self.step) {
					case 1:
						if(model.first_name && model.last_name) {
							isValid = true;
						} else {
							error['fullname'] = 'Please enter your fullname';
						}
						break;
					case 2:
						if(model.email && model.consent_given === true) {
							isValid = true;
						}
						break;
					case 3:
						if(model.my_smile_has) {
							isValid = true;
						}
						break;
					case 4:
						if(model.smile_rate) {
							isValid = true;
						}
						break;
					case 5:
						if(model.do_you_have_any) {
							isValid = true;
						}
					case 6:
						if(model.phone_number) {
							isValid = true;
						}
					case 7:
						if(model.postcode) {
							isValid = true;
						}
						break;
					default:
						break;
				}
				/*$('.error').remove();
				$.each(error, function(k, v) {
					$('[data-field="' + k + '"]').append('<span class="error">' + v + '</span>');
				});*/
				return isValid;
			},
			changeStep(direction) {
				let self = this, proceed = true;
				if(direction === 'next') {
					proceed = self.validateForm();
				}
				if(proceed) {
					if(self.step >= 1 && self.step <= self.total_steps) {
						if(direction === 'next') {
							self.step += 1;
						}
						if(direction === 'prev') {
							self.step -= 1;
						}
					}
					self.goToStep(self.step - 1);
					self.calcProgress();
				}
			},
			goToStep(step) {
				$('.step').hide().eq(step).show();
			},
			calcProgress() {
				let self = this;
				var total_steps = self.total_steps,
					percentage = ((self.step) * 100 / (total_steps + 1)).toFixed(0);
				$('.percentage').text(percentage + '%');
				$('.bar-completion').width(percentage + '%');
			},
			initModel() {
				let self = this;
				self.model = {
					first_name: "",
					last_name: "",
					email: "",
					phone_number: "",
					postcode: "",
					my_smile_has: "",
					smile_rate: 0,
					do_you_have_any: "",
					consent_given: false,
					marketing_consent_given: false
				}
			},
			initMounted() {
				let self = this;
				self.goToStep(self.step - 1);
				self.total_steps = $('#quiz .step').length;
				self.calcProgress();
				self.initModel();
				var $slider = $("#slider").slider({
					orientation: "horizontal",
					value: 3,
					min: 1,
					max: self.smile_rating_options.length,
					step: 1,
					slide: function(event, ui) {
						$('.slider-labels').find('span').removeClass('active').eq(ui.value - 1).addClass('active');
					},
					stop: function(event, ui) {
						setTimeout(() => {
							self.setValue('smile_rate', ui.value, self.smile_rating_options[ui.value - 1]);
						}, 500);
					}
				});
				$slider.each(function() {
					var opt = $(this).data().uiSlider.options;
					var vals = opt.max - opt.min;
					for (var i = 0; i <= vals; i++) {
						var el = $('<span>' + (i + 1) + '</span>');
						$('#slider-rate').find('.slider-labels').append(el);
					}
				});
				self.params = helper.getUrlParams();
				$('input[type="tel"]').bind("paste", function(e) {
					var pastedData = e.originalEvent.clipboardData.getData('text'), 
						tel_format = pastedData.replace(/\D/g, '');
					$(this).val(tel_format);
				});
				// remove spaces from input tels
				$('input[type="tel"]').bind('input', function() {
					$(this).val(function(_, v) {
						return v.replace(/\s+/g, '').replace(/[^0-9]/g, '');
					});
				});
			}
		},
		computed: {
			isFirstStep() {
				let self = this;
				return self.step === 1;
			},
			isLastStep() {
				let self = this;
				return self.step === self.total_steps;
			},
			getUtmParams() {
				let self = this, utms = [];
				if(self.params) {
					Object.entries(self.params).forEach(([key, value]) => {
						if(key.includes('utm_')) {
							utms.push({
								key: key,
								value: value
							});
						}
					});
				}
				return utms;
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>